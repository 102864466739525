'use strict';

var focusHelper = require('base/components/focus');
var login = require('../account/login');
var floatLabel = require('core/floatLabel');
var clientSideValidation = require('core/components/clientSideValidation');
var cartInstoreOptions = require('../cart/cartInstoreOptions');
var applePayCartButton = require('./applePayCartButton');
var cartHelper = require('./cartHelper');
var atmHelper = require('../atm/atmHelper');
var updateAddToCart = require('../product/updateAddToCart');

/** Constants */
const $body = $('body');

function updateDeselectIcons(pid) {
  var product = $body.find('div.product[data-pid="' + pid +'"]');
  var heartButton = $(product).find('button.wishlistTile');
  if(!heartButton.length) return false;
  if(heartButton.is('.deselect-wishlist')) {
    heartButton.removeClass('deselect-wishlist js-cart-wishlist-tile');
  }
  if(heartButton.is('.plpremove-wishlist')) {
    heartButton.removeClass('plpremove-wishlist');
  }
  if(!heartButton.is('select-wishlist')) {
    heartButton.addClass('select-wishlist');
  }
  heartButton.find('span').removeClass('svg-svg-96-heart-gray svg-svg-96-heart-gray-dims');
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotificationOnLineItem(message, uuid, productID, productCode) {
  var errorProducts = [];
  if (productID && productCode) {
    errorProducts.push({
      code: productCode,
      selected_sku: productID
    });
  }
  var errorInfo = atmHelper.buildErrorInfo(errorProducts, 'cart error', message, 'server-side');
  $body.trigger('adobeTagManager:trackError',  errorInfo);
  let errorHtml = '<div class="alert alert-dismissible valid-cart-error line-item-error' + 'fade show" role="alert">' + message + '</div>';
  let $qtyError = $('.qty-error-' + uuid);
  $qtyError.html(errorHtml);

  setTimeout(function () {
    $qtyError.html('');
  }, 3000);
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotificationQuickView(message) {
  const errorInfo = atmHelper.buildErrorInfo([], 'cart error', message, 'server-side');
  const $editProductModal = $('#editProductModal');
  const $modalBody = $editProductModal.find('.modal-body');
  const $errorMsg = $('div.error-msg');
  $body.trigger('adobeTagManager:trackError',  errorInfo);
  const errorHtml =
    '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
    'fade show" role="alert">' +
    message +
    '</div>';

    $errorMsg.html(errorHtml);
    $modalBody.animate({ scrollTop: $errorMsg.position().top }, 300);
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
  let lineItem;
  let messages = '';

  for (let i = 0; i < data.items.length; i++) {
    if (data.items[i].UUID === uuid) {
      lineItem = data.items[i];
      break;
    }
  }

  if (lineItem.availability) {
    if (lineItem.availability.messages) {
      lineItem.availability.messages.forEach(function (message) {
        messages += '<p class="line-item-attributes">' + message + '</p>';
      });
    }

    if (lineItem.availability.inStockDate) {
      messages += '<p class="line-item-attributes line-item-instock-date">' + lineItem.availability.inStockDate + '</p>';
    }
    $('.uuid-' + uuid)
      .find('.cannot-shipping-message')
      .empty();
  }

  $('.availability-' + lineItem.UUID).html(messages);
}

/**
 * Show Gift Card purchase limit error message
 */
function updateGiftCardError(data) {
  if ($('body').find('.error-messaging').length == 0) {
    $('body').append('<div class="error-messaging"></div>');
  }
  if (data && data.giftCardError) {
    if(data && data.renderHTML) {
      clientSideValidation.customCreateErrorNotification($('.error-messaging'));
      $('.error-messaging.giftcard-cart-page-error .error-content').html(data.renderHTML);
    }
  } else if ($('.cart-page-content').data('giftcardError')) {
    $('.error-messaging').addClass('giftcard-cart-page-error');
    let url = $('.cart-page-content').data('giftCardValidationUrl');
    $.ajax({
      url: url,
      type: 'get',
      dataType: 'json',
      success: function (data) {
       if (data.error && data.giftCardError) {
        clientSideValidation.customCreateErrorNotification($('.error-messaging'));
        $('.error-messaging.giftcard-cart-page-error .error-content').html(data.renderHTML);
       }
      },
      error: function (data) {
        console.log('Gift Card validation error');
      }
    });
  }
}

/**
 * Finds an element in the array that matches search parameter
 * @param {array} array - array of items to search
 * @param {function} match - function that takes an element and returns a boolean indicating if the match is made
 * @returns {Object|null} - returns an element of the array that matched the query.
 */
function findItem(array, match) {
  for (let i = 0, l = array.length; i < l; i++) {
    if (match.call(this, array[i])) {
      return array[i];
    }
  }
  return null;
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateProductDetails(data, uuid) {
  let lineItem = findItem(data.cartModel.items, function (item) {
    return item.UUID === uuid;
  });

  cartHelper.updateItemsHTML(data.cartModel);

  if (lineItem.waitlistable && !lineItem.available) {
    let addwaitlistText = $('.js-cart-edit[data-uuid="' + uuid + '"]').data('addwaitlisttext');
    $('.js-cart-edit[data-uuid="' + uuid + '"]').text(addwaitlistText);
  } else {
    let editText = $('.js-cart-edit[data-uuid="' + uuid + '"]').data('edit');
    $('.js-cart-edit[data-uuid="' + uuid + '"]').text(editText);
  }
}

/**
 * Updates details of a product line items in the Mini-Cart Section
 * @param {Object} data - AJAX response from the server
 */
function updateMiniCartItemsHTML(data) {
  $('.mini-cart-container').html(data.minicart);
  window.formFields.updateSelect();
}

/**
 * Get Product options
 * @param {Object} $productContainer product container
 * @return {string} returns json value
 */
function getOptions($productContainer) {
  let options = $productContainer
    .find('.product-option')
    .map(function () {
      let $this = $(this);
      let $elOption = $this.find('.options-select');
      let urlValue = $elOption.val();
      let selectedValueId = $elOption.find('option[value="' + urlValue + '"]').data('value-id');
      return {
        optionId: $this.data('option-id'),
        selectedValueId: selectedValueId
      };
    })
    .toArray();

  return JSON.stringify(options);
}

/**
 * Get all bundled products
 * @return {string} returns json value
 */
function getChildProducts() {
  let childProducts = [];
  $('.bundle-item').each(function () {
    let $this = $(this);

    childProducts.push({
      pid: $this.find('.product-id').text(),
      quantity: parseInt($this.find('label.quantity').data('quantity'), 10)
    });
  });

  return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**
 * Generates the modal window on the first call.
 *
 */
function getOptionalBonusModalHtmlElement() {
  var $editProductModal = $('#editProductModal');

  if ($editProductModal.length !== 0) {
    $editProductModal.remove();
  }
  let htmlString =
    '<!-- Modal -->' +
    '<div class="modal fade gwp" id="editProductModal" tabindex="-1" role="dialog">' +
    '<span class="enter-message sr-only" ></span>' +
    '<div class="modal-dialog gift-offers quick-view-dialog ">' +
    '<!-- Modal content-->' +
    '<div class="modal-content">' +
    '<div class="modal-header">' +
    '    <button type="button" class="close pull-right float-right" data-dismiss="modal">' +
    '        <span aria-hidden="true" class="cancel-icon svg-svg-22-cross svg-svg-22-cross-dims"></span>' +
    '    </button>' +
    '    <span aria-hidden="true" class="choice-bonus-header"></span>' +
    '    <span class="sr-only"> </span>' +
    '</div>' +
    '<div class="modal-body"></div>' +
    '<div class="modal-footer"></div>' +
    '</div>' +
    '</div>' +
    '</div>';
  $body.append(htmlString);
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
  var $editProductModal = $('#editProductModal');

  if ($editProductModal.length !== 0) {
    $editProductModal.remove();
  }
  let htmlString =
    '<!-- Modal -->' +
    '<div class="modal fade" id="editProductModal" tabindex="-1" role="dialog">' +
    '<span class="enter-message sr-only" ></span>' +
    '<div class="modal-dialog quick-view-dialog">' +
    '<!-- Modal content-->' +
    '<div class="modal-content">' +
    '<div class="modal-header">' +
    '    <button type="button" class="close pull-right float-right" data-dismiss="modal">' +
    '        <span aria-hidden="true" class="cancel-icon svg-36-avenue-Up_Copy svg-36-avenue-Up_Copy-dims"></span>' +
    '    </button>' +
    '    <span class="sr-only"> </span>' +
    '</div>' +
    '<div class="modal-body"></div>' +
    '<div class="modal-footer"></div>' +
    '</div>' +
    '</div>' +
    '</div>';
  $body.append(htmlString);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
  let $html = $('<div>').append($.parseHTML(html));

  let body = $html.find('.product-quickview');
  let footer = $html.find('.modal-footer').children();

  return {
    body: body,
    footer: footer
  };
}

function displayWaitListOptMsg() {
  $body.on('focus keyup', '.js-waitlist-mobile, .js-waitlist-email', function () {
    let $waitListMobile = $('.js-waitlist-mobile');

    if ($waitListMobile.val() !== '' || $('.js-waitlist-email').val() !== '') {
      $('.js-mobile-opt-msg').removeClass('d-none');
      if ($waitListMobile.val() !== '') {
        $waitListMobile.attr('required', true);
        $waitListMobile.attr('pattern', $(this).attr('data-pattern'));
      } else {
        $waitListMobile.removeAttr('required');
        $waitListMobile.removeAttr('pattern');
      }
    } else {
      $('.js-mobile-opt-msg').addClass('d-none');
      if ($waitListMobile.val() == '') {
        $waitListMobile.removeAttr('required');
        $waitListMobile.removeAttr('pattern');
      }
    }
  });
  $body.on('blur', '.js-waitlist-mobile', function () {
    if ($(this).val() == '') {
      let $waitListMobile = $('.js-waitlist-mobile');
      let $formGroup = $waitListMobile.closest('.form-group');

      $waitListMobile.removeAttr('required');
      $waitListMobile.removeAttr('pattern');
      $waitListMobile.removeClass('is-invalid').next('span').remove();
      $formGroup.find('.invalid-feedback').empty();
      $formGroup.find('label').removeClass('is-invalid').removeClass('input-focus');
    }
  });
}

function submitWaitList() {
  $body.on('submit', 'form.waitlistForm', function (e) {
    let form = $(this);
    e.preventDefault();
    let url = form.attr('action');
    form.spinner().start();
    let $productContainer = $(form).closest('.product-quickview.product-detail');
    $.ajax({
      url: url,
      type: 'post',
      dataType: 'json',
      data: form.serialize(),
      success: function (data) {
        if (data.success) {
          $productContainer
            .find('.wait-list-success')
            .removeClass('d-none')
            .html('<div class="alert-success"><div class="success-message"><span class="message">' + data.msg + '</span></div></div>');
          $productContainer.find('.js-wait-list-form').addClass('d-none');
          if($productContainer && $productContainer.find('.waitlistForm') && $productContainer.find('.waitlistForm').length > 0){
            $productContainer.find('.waitlistForm')[0].reset();
          }
          $productContainer.find('.waitlistForm').find('.input-focus').removeClass('input-focus');
          $body.trigger('adobe:waitListComplete', data);
        } else {
          $productContainer.find('.wait-list-success').empty().text(data.msg);
        }
        form.spinner().stop();
      },
      error: function (data) {
        if (data && data.msg) {
          var errorInfo = atmHelper.buildErrorInfo([], 'cart error', data.msg, 'server-side');
          $body.trigger('adobeTagManager:trackError',  errorInfo);
          $productContainer.find('.wait-list-success').empty().text(data.msg);
        }
        form.spinner().stop();
      }
    });
    return false;
  });
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
function fillModalElement(editProductUrl) {
  let $editProductModal = $('#editProductModal')

  $editProductModal.modal('show');
  $('.quick-view-dialog .modal-content').spinner().start();
  $.ajax({
    url: editProductUrl,
    method: 'GET',
    dataType: 'json',
    success: async function (data) {
      if (!window.s7viewers) {
        var s7viewersLoad = require('core/util/s7viewers').loadS7ViewersScripts;
        await s7viewersLoad(data.s7APIForPDPVideoPlayer);
      }

      let parsedHtml = parseHtml(data.renderedTemplate);
      let $modalBody = $editProductModal.find('.modal-body');

      $modalBody.html(parsedHtml.body);
      $editProductModal.find('.modal-footer').html(parsedHtml.footer);
      $editProductModal.find('.modal-header .close .sr-only').text(data.closeButtonText);
      $editProductModal.find('.enter-message').text(data.enterDialogMessage);
      $editProductModal.find('.pdt-name-edit').empty();

      if (data.product.brand.name) {
        $editProductModal.find('.pdt-name-edit').text(data.product.brand.name);
      }
      // Remove sizechart on cart edit
      $editProductModal.find('.size_guide').empty();
      window.setTimeout(function () {
        // Fix for strange issue with Slick slider image width calculation
        window.hbcSlider.hbcSliderInit('edit-product');
      }, 200);
      window.formFields.updateSelect();
      $modalBody.find('.js-mobile-opt-msg').html($('.js-waitlist-opt-message').html());
      displayWaitListOptMsg();
      submitWaitList();
      window.hbcTooltip.tooltipInit();

      let $waitListForm = $('form.waitlistForm');

      if ($waitListForm.is(':visible')) {
        floatLabel.resetFloatLabel();
        $waitListForm
          .find('input:visible')
          .each(function () {
            if ($(this).val() !== '') {
              clientSideValidation.validateFormonBlur.call(this);
            }
          });
      }
      $.spinner().stop();
    },
    error: function () {
      $.spinner().stop();
    }
  });
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} data - render data html string
 */
function fillChoiceOfBonusModalElement(data) {
  let parsedHtml = data.renderedTemplate;
  let $editProductModal =  $('#editProductModal');

  $editProductModal.find('.modal-body').html(parsedHtml);
  $editProductModal.find('.choice-bonus-header').html(data.bonusModel.title);
  $editProductModal.modal('show');
}

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 */
function confirmDelete(actionUrl, productID, productName, uuid) {
  let $deleteConfirmBtn = $('.cart-delete-confirmation-btn');
  let $productToRemoveSpan = $('.product-to-remove');

  $deleteConfirmBtn.data('pid', productID);
  $deleteConfirmBtn.data('action', actionUrl);
  $deleteConfirmBtn.data('uuid', uuid);

  $productToRemoveSpan.empty().append(productName);
}

/**
 * Changes done for wishlist
 * @param {Object} attrs product varition attributes
 * @param {Object} $productContainer product container
 */
function updateAttrs(attrs, $productContainer) {
  attrs.forEach(function (attr) {
    let $attr = '[data-attr="' + attr.id + '"]';
    let $defaultOption = $productContainer.find($attr + ' .select-' + attr.id + ' option:first');
    $defaultOption.attr('value', attr.resetUrl);
    attr.values.forEach(function (attrValue) {
      let $attrValue = $productContainer.find($attr + ' [data-attr-value="' + attrValue.value + '"]');
      $attrValue.attr('value', attrValue.url).removeAttr('disabled');

      if (!attrValue.selectable) {
        $attrValue.attr('disabled', true);
      }
    });
  });
}

/**
 * Changes done for wishlist
 * @param {Object} selectedValueUrl product selected variant
 * @param {Object} $productContainer product container
 */
function attributeSelect(selectedValueUrl, $productContainer) {
  if ($productContainer.length && selectedValueUrl) {
    $productContainer.spinner().start();
    $.ajax({
      url: selectedValueUrl,
      method: 'GET',
      success: function (data) {
        updateAttrs(data.product.variationAttributes, $productContainer);
        $productContainer.closest('.product-detail-wlcart').data('pid', data.product.id);
        $productContainer.closest('.product-detail-wlcart').data('producttype', data.product.productType);
        $productContainer
          .closest('.product-detail-wlcart')
          .find('.prod_wl_bttn')
          .data('readytoorder', data.product.readyToOrder && data.product.available);

        let $priceSelector = $('.prod-price', $productContainer);
        $priceSelector.html(data.product.price.html);
        if (data.product.price.sales !== undefined && data.product.price.sales != null) {
          $('.product-price-selected', $productContainer).empty().text(data.product.price.sales.formatted);
        }

        // Update promotions
        let $promotions = $('.promotions', $productContainer);
        let $promotionPricing = $('.promotion-pricing', $productContainer);

        if (data.product.promotionalPricing && data.product.promotionalPricing.isPromotionalPrice && data.product.promotionalPricing.promoMessage !== '') {
          $promotionPricing.html(data.product.promotionalPricing.priceHtml);
          $promotionPricing.removeClass('d-none');
          $('.promotions', $productContainer).addClass('d-none');
        } else {
          $promotionPricing.addClass('d-none');
          $promotions.removeClass('d-none');
          $promotions.html(data.product.promotionsHtml);
        }

        // Update limited inventory message
        if (data.product.availability.isAboveThresholdLevel) {
          $('.limited-inventory', $productContainer).empty().text(data.resources.limitedInventory);
        } else {
          $('.limited-inventory', $productContainer).empty();
        }

        let $prodWlButton = $productContainer.find('button.prod_wl_bttn');

        if (data.product.productType === 'master') {
          $productContainer.find('.alert-msg').show();
        } else if ((!data.product.available || !data.product.readyToOrder) && data.product.productType !== 'master') {
          $prodWlButton.addClass('soldout');
          $prodWlButton.removeClass('add-to-cart');
          $prodWlButton.text(data.resources.soldout);
          $prodWlButton.attr('disabled', true);
        } else {
          $prodWlButton.addClass('add-to-cart');
          $prodWlButton.removeClass('soldout');
          $prodWlButton.text(data.resources.movetobag);
          $prodWlButton.removeAttr('disabled');
        }
        $productContainer.spinner().stop();
        let isSelectReady = $productContainer.find('button.add-to-cart').data('readytoorder');
        if (isSelectReady) {
          $productContainer.find('.alert-msg').addClass('d-none');
          $productContainer.find('.selected-option').removeClass('error');
        }
      },
      error: function () {
        $productContainer.spinner().stop();
      }
    });
  }
}

/**
 * Changes done for wishlist
 * @param {Object} $elementAppendTo element
 * @param {string} msg message to display
 */
function displayErrorMessage($elementAppendTo, msg) {
  var errorInfo = atmHelper.buildErrorInfo([], 'cart error', msg, 'server-side');
  $body.trigger('adobeTagManager:trackError',  errorInfo);
  if ($('.remove-from-wishlist-messages').length === 0) {
    $elementAppendTo.append('<div class="remove-from-wishlist-messages "></div>');
  }

  let $removeFromWishlistMsg = $('.remove-from-wishlist-messages');

  $removeFromWishlistMsg.append('<div class="remove-from-wishlist-alert text-center alert-danger">' + msg + '</div>');

  setTimeout(function () {
    $removeFromWishlistMsg.remove();
  }, 3000);
}

/**
 * init for SignIn Model
 */
function initSignIn() {
  $body.on('click', '.cart-empty .signin', function () {
    login.openLoginPopup($(this).data('url'));
  });
}

/**
 * Init for validate bonus product
 */
function initValidateOptionalBonusSelect() {
  $('body').on('validateBonusChoiceProduct', function (e, $this) {
    let maxPids = $('.choose-bonus-product-dialog').data('total-qty');
    let totalQty = $('input[name="chooseBonusproductIds"]:checked').length;
    if (totalQty <= maxPids) {
      $('.choice-of-bonus-product .error-div').empty();
      $('.pre-cart-products').html($('input[name="chooseBonusproductIds"]:checked').length);
      $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
    } else {
      $($this).prop('checked', false);
      // $('.selected-bonus-products .bonus-summary').addClass('alert-danger');
    }
  });
}

/**
 * Init for tax calculation
 */
function initOptionalBonusSelect() {
  $body.on('click', '.bonus-product-item input[name="chooseBonusproductIds"]', function () {
    $body.trigger('validateBonusChoiceProduct', this);
  });
}

/**
 * Init for product detail bonus product
 */
function initChooseBonusProductDetails() {
  $body.on('click', '.gwp-pdt-details .product-details', function () {
    $(this).closest('div').find('.productDetailModal').addClass('show');
  });
}

// wishlist Floating labelwithout selecting the value.
let floatinglabelWishlist = function () {
  $('.wishlistItemCards .add-to-cart').each(function () {
    let $this = $(this);
    let isReadyToOrder = $this.data('readytoorder');
    if (!isReadyToOrder) {
      let $card = $this.closest('.card');
      $card.find('.non-input-label').removeClass('input-focus');
      $card.find('.selected-option span').addClass('d-none');
    }
  });
};

// wishlist product Tool tip.
let tooltipWishlist = function () {
  $body.on('mouseover', '.wl-tooltip', function () {
    $(this).parents('.custom-tooltip').find('.tooltip-wlsale').attr('aria-hidden', 'false');
  });

  $body.on('mouseleave', '.wl-tooltip', function () {
    $(this).parents('.custom-tooltip').find('.tooltip-wlsale').attr('aria-hidden', 'true');
  });

  $body.on('focus', '.wl-tooltip', function () {
    $(this).parents('.custom-tooltip').find('.tooltip-wlsale').attr('aria-hidden', 'false');
  });

  $body.on('keydown', '.wl-tooltip', function (e) {
    if (e.which === 27) {
      $(this).parents('.custom-tooltip').find('.tooltip-wlsale').attr('aria-hidden', 'true');
      e.preventDefault();
      return false;
    }
    return true;
  });

  $body.on('click', '.js-cart-wishlist-tile', function (e) {
    e.preventDefault();
    if ($('.pg-name').length > 0 && $('.pg-name').val() === 'wishlist') {
      return;
    }
    let element = $(this);
    if (element.hasClass('deselect-wishlist')) {
      var pid = element.closest('div.product').data('pid');
      let wishlistItems = $body.find('.wishlist-cart-slider');
      removeWishlistSlider();
      if(wishlistItems && wishlistItems.length) {
        var wishlistItemToRemove = wishlistItems.find('div.product[data-pid="' + pid +'"]');
        if(wishlistItemToRemove && wishlistItemToRemove.length) {
          wishlistItemToRemove.parent().remove();
        }
      }
      updateDeselectIcons(pid);
      let products = wishlistItems.find('.product');
      if (products.length < 16) {
        $body.find('.wishlist-all').addClass('d-none');
      }
      if (products.length === 0) {
        let $wishListCart = $body.find('.wishlist-cart');

        $wishListCart.find('.wishlist-cart-heading').remove();
        $wishListCart.find('.wishlist-cart-slider').remove();
      }
      if (products.length > 0) {
        window.hbcSlider.hbcSliderInit('wishlist-cart-slider');
        window.hbcSlider.cartReccommendationSliderInit();
      }
    }
  });

  $body.on('click', '.select-wishlist', function (e) {
    e.preventDefault();
    let $this = $(this).closest('.product');

    if ($body.hasClass('cart-page')) {
      let wishlistCart = $body.find('.wishlist-cart');
      let pid = $this.data('pid');
      let isWishlisted = wishlistCart.find('.product[data-pid='+pid+']').length;
      let $tileWrapper = $('<div class="tile-wrapper tile-hide-swatches"></div>');
      let $tileSlider = wishlistCart.find('.wishlist-cart-slider');
      if (wishlistCart && $tileSlider.length === 0) {
        wishlistCart
          .find('.row')
          .html(
            '<div class="wishlist-cart-heading slider-heading text-center"><a class="link-wishlist" href="' +
              wishlistCart.data('wishlisturl') +
              '">' +
              '<span class="svg-24-avenue-wishlist-heart-dims svg-24-avenue-wishlist-heart heart-icon"></span> ' +
              wishlistCart.data('wishlistheader') +
              '</a></div><div class="col-12 hbc-slider wishlist-cart-slider"></div>'
          );
        $tileWrapper.append($this.clone());
        wishlistCart.find('.wishlist-cart-slider').html($tileWrapper);
      } else if (!isWishlisted) {
        removeWishlistSlider();
        let tile = $tileSlider.find('.product-tile');
        let $firstEl;

        $tileWrapper.append($this.clone());
        if (tile.closest('.product').length > 0) {
          $firstEl = tile.closest('.product').eq(0).parent();
          $firstEl.before($tileWrapper);
        } else {
          $tileSlider.append($tileWrapper)
        }
      }
      // eslint-disable-next-line newline-per-chained-call
      $tileSlider.find('.product-tile').eq(0).find('.wishlistTile').removeClass('select-wishlist').addClass('deselect-wishlist js-cart-wishlist-tile');

      if ($tileSlider.find('.product-tile').length >= 16) {
        $body.find('.wishlist-all').removeClass('d-none');
      }
      window.hbcSlider.hbcSliderInit('wishlist-cart-slider');
      window.hbcSlider.cartReccommendationSliderInit();
    }
  });

  $(document).ready(function () {
    floatinglabelWishlist();
    $('.checkout-btn, .proxy-checkout-btn').removeClass('disabled');
  });
};

tooltipWishlist();

/* Display size swatches for wishlist slider for mobile */
$(window).on('load resize orientationchange', function () {
  $('.wishlist-cart-slider .product-tile').each(function () {
    let initialIndex = 0;
    let $this = $(this);
    let $firstSelectableIndex = $this.find('.firstSelectableIndex');

    if ($firstSelectableIndex.length > 0) {
      let initialIndex = $firstSelectableIndex.eq(0).attr('data-firstSelectableIndex');
    }

    if ($(window).width() < 1023.99) {
      if (!$this.find('.size').hasClass('slick-initialized')) {
        $this.find('.size').slick({
          dots: false,
          infinite: false,
          speed: 300,
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
          autoplay: false
        });

        if ($this.find('.size').eq(0).find('.slick-slide').length - 1 == Number(initialIndex)) {
          $this.find('.size')
            .slick('slickGoTo', Number(initialIndex) - 1, true);
        } else {
          $this.find('.size').slick('slickGoTo', Number(initialIndex), true);
        }
      }
    }
  });
  if((window.innerWidth >= 1024 && $('.recommendation-products').hasClass('touchscreen-slider-initialized')) || (window.innerWidth < 1024 && !$('.recommendation-products').hasClass('touchscreen-slider-initialized'))) {
    if(window.innerWidth >= 1024 && $('.recommendation-products').hasClass('touchscreen-slider-initialized')) {
      $('.recommendation-products').removeClass('touchscreen-slider-initialized');
    }
    window.hbcSlider.hbcSliderInit('recommendation-products');
  }
});

/**
 * Mini cart height
 *
*/
function getMiniCartHeight() {
  if ($('.main-menu').is('.fixed')) {
    $($('.minicart .product-summary')[1]).css(
      'height',
      $($('.minicart .popover')[1]).outerHeight(true) -
        ($($('.minicart-footer')[1]).outerHeight(true) + $($('.bag-heading')[1]).outerHeight(true) + $('.main-menu').outerHeight(true))
    );
  } else {
    $($('.minicart .product-summary')[0]).css(
      'height',
      $($('.minicart .popover')[0]).outerHeight(true) -
        ($($('.minicart-footer')[0]).outerHeight(true) +
          $($('.bag-heading')[0]).outerHeight(true) +
          $('.header').outerHeight(true) -
          $(window).scrollTop() +
          33)
    );
  }
}

/**
 * Mini cart Scroll Height
 */
function setMiniCartScrollHeight() {
  let winHt = $(window).outerHeight();
  let $miniCartFooter = $('.minicart-footer');
  let mcFooterHt = $miniCartFooter.outerHeight();
  let $miniCart = $('.minicart');
  if ($('.main-menu').is('.fixed')) {
    mcFooterHt = $($miniCartFooter[1]).outerHeight();
  } else {
    mcFooterHt = $($miniCartFooter[0]).outerHeight();
  }
  if (winHt < 786) {
    $miniCart.find('.cart').css('height', 410);
    $miniCart.find('.product-summary').css({
      'max-height': 410 - mcFooterHt
    });
  } else {
    $miniCart.find('.cart').css('height', 610);
    $miniCart.find('.product-summary').css({
      'max-height': 610 - mcFooterHt
    });
  }
}

/**
 * Function to remove slider from wishlist
 */
function removeWishlistSlider() {
  let $wishlistSlider = $body.find('.wishlist-cart-slider');
  if ($wishlistSlider.is('.slick-initialized')) {
    $wishlistSlider.slick('unslick');
  }
  if ($wishlistSlider.is('.touchscreen-slider-initialized')) {
    $wishlistSlider.removeClass('touchscreen-slider-initialized');
  }
}

/**
 * Handle Update Quantity Errors in cart and minCart
 * @param {Object} response response object
 * @param {jquery} $quantitySelect - Quantity Select container
 */
function handleUpdateQuantityErrors (response, $quantitySelect, uuid, productID, productCode) {
  if (response.redirectUrl) {
    window.location.href = response.redirectUrl;
  } else {
    createErrorNotificationOnLineItem(response.errorMessage, uuid, productID, productCode);

    // SFDEV-1933
    var $this = $quantitySelect.closest('div').find('input[class*="quantity-val"]');
    var currentVal = $this.val();
    var availableATS = parseInt(response.availableATS || '1');

    if (currentVal > availableATS) {
      setTimeout(function(){
      $this.val(parseInt(availableATS, 10));
      }, 3000);
    }

    var minValue = parseInt($this.attr('min'), 10);
    var maxValue = parseInt($this.attr('max'), 10);
    var valueCurrent = parseInt($this.val(), 10);
    var name = $this.attr('name');

    if (valueCurrent >= minValue) {
      $this
        .closest('div')
        .find(".btn-number[data-type='minus'][data-field='" + name + "']")
        .removeAttr('disabled'); // eslint-disable-line
    } else {
      $this.val($quantitySelect.data('oldValue'));
    }

    if (valueCurrent <= maxValue) {
      $this
        .closest('div')
        .find(".btn-number[data-type='plus'][data-field='" + name + "']")
        .removeAttr('disabled'); // eslint-disable-line
    }

    if (valueCurrent === maxValue) {
      $this
        .closest('div')
        .find(".btn-number[data-type='plus'][data-field='" + name + "']")
        .attr('disabled', true); // eslint-disable-line
    } // update Drop Down
  }
  $.spinner().stop();
}

/**
 * Click event for PWP add to cart button
 */
function pwpAddToCart() {
  $body.on('click', '.pwp-add-to-cart', function (e) {
    var addToCartUrl = $('.add-to-cart-url').val();
    var $this = $(this);
    var pidsObject = {
      bonusProducts: []
    };

    pidsObject.bonusProducts.push({
      pid: $this.data('pid'),
      qty: 1,
      options: []
    });
    pidsObject.totalQty = 1;

    var form = {
      pid: $this.data('pid'),
      quantity: 1,
      pids: JSON.stringify(pidsObject),
      pliuuid: $this.data('pliuuid'),
      uuid: $this.data('uuid')
    };

    if (addToCartUrl) {
      $this.parent().spinner().start();

      $.ajax({
        url: addToCartUrl,
        method: 'POST',
        data: form,
        success: function (data) {
          if (!data.error) {
            $body.trigger('cart:update');
            $this.attr("disabled", true);
            window.location.reload();
          }
        },
        error: function () {
          $.spinner().stop();
        },
        complete: function () {
          $.spinner().stop();
        },
      });
    }
  });
}
/**
 * Click event for PWP select your offer button
 */
function pwpSelectOffer() {
  $body.on('click', '.pwp-select-offer', function (e) {
    let $this = $(this);

    if ($this.data('url')) {
      $.spinner().start();
      $.ajax({
        url: $this.data('url'),
        method: 'GET',
        dataType: 'json',
        success: function (data) {
          window.base.methods.editBonusProducts(data, true);
          $.spinner().stop();
        },
        error: function () {
          $.spinner().stop();
        }
      });
    }
  });
}

/**
 * Display or hide Klarna message
 * @param {Object} basket - basket data returned from AJAX
 */
function toggleKlarnaMessage(basket) {
  if (basket?.totals?.preorder?.hasOnePreOrderItem) {
    $('.klarna-container').addClass('d-none');
  } else {
    $('.klarna-container').removeClass('d-none');
  }
}

function handleMixedCartForApplePay(data) {
  //Hide apple pay for mixed cart scenarios and show for non mixed cart scenarios
  if (window.dw &&
    window.dw.applepay &&
    window.ApplePaySession &&
    window.ApplePaySession.canMakePayments()) {
    var applePayCartBtn = $('#apple-pay-cart-button');
    if (!data.isApplePayEnabled) {
      if (applePayCartBtn.length) {
        $('#apple-pay-cart-button').addClass('d-none')
      }
      var checkoutOptions = $('.checkout-options');
      //Hide "Or Checkout With" if inside the div has only the div with the text
      if (checkoutOptions.length && checkoutOptions.children().length === 1 && checkoutOptions.children()[0].className.indexOf('chkout-optn-text') > -1){
          checkoutOptions.addClass('d-none');
      //Hide "Or Checkout With" if inside the div has only the empty apple pay div and no other payment method like paypal, masterpass...
      } else if (checkoutOptions.length && checkoutOptions.children().length <= 2) {
          for (var i = 0; i < checkoutOptions.children().length; i++) {
              var child = checkoutOptions.children()[i];
              if (child.id === 'apple-pay-cart-button') {
                  checkoutOptions.addClass('d-none');
              }
          }
      }
    } else {
      if (applePayCartBtn.length) {
        $('#apple-pay-cart-button').removeClass('d-none')
      }
      var checkoutOptions = $('.checkout-options');
      checkoutOptions.removeClass('d-none');
      var checkoutOptions = $('.checkout-options');
      if (checkoutOptions.length) {
          checkoutOptions.addClass('applepay-active');
          checkoutOptions.addClass('float-left');
      }
      var masterPassContainer = $('.masterpass-cart-buttons-container');
      if (masterPassContainer.length) {
          masterPassContainer.removeClass('chkout-btn-img');
          masterPassContainer.removeClass('float-right');
          var masterPassParent = masterPassContainer.parent();
          if (masterPassParent.length && masterPassParent.hasClass('MP-wrap')) {
              masterPassParent.removeClass('MP-wrap');
              masterPassParent.addClass('masterpass-payment');
              masterPassParent.addClass('chkout-btn-img-iOS');
          }
      }
      var paymentButtons = $('.chkout-btn-img');
      if (paymentButtons.length) {
          paymentButtons.addClass('chkout-btn-img-iOS');
          paymentButtons = $('.chkout-btn-img-iOS')
          if (paymentButtons.length) {
              paymentButtons.removeClass('chkout-btn-img')
          }
      }
    }
  }
}

module.exports = function () {
  $(document).on('show.bs.modal', () => {
    var pageTop = `-${window.scrollY}px`;
    document.body.style.position = 'fixed';
    document.body.style.top = pageTop;
  }).on('hidden.bs.modal', () => {

    const scrollY = document.body.style.top;
    document.body.style.position = '';
    document.body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  });
  cartInstoreOptions.selectStoreCart();

  $('body').on('afterRemoveFromCart', function (e, data) {
    e.preventDefault();
    confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
  });

  $('.optional-promo').click(function (e) {
    e.preventDefault();
    $('.promo-code-form').toggle();
  });

  $('body').on('product:beforeAttributeSelect', function () {
    $('.modal.show .modal-content').spinner().start();
  });

  // wishlist changes
  $body.on('click', '.remove-prod-from-wishlist', function (e) {
    e.preventDefault();
    var url = $(this).data('url');

    $.spinner().start();
    $.ajax({
      url: url,
      type: 'get',
      dataType: 'json',
      success: function (data) {
        if (data.totals && data.totals.discountsHtml) {
          $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
        }

        // Since deleting product in wishlist will not affect anything I am deleting this
        cartHelper.updateItemsHTML(data);

        window.formFields.adjustForAutofill();
        floatinglabelWishlist();
        if (data.deletedProduct) {
          $body.trigger('adobeTagManager:removeFromFav', data.deletedProduct);
        }
        $.spinner().stop();
      },
      error: function () {
        $.spinner().stop();
        var $elToAppendWL = $('.wishlistItemCards');
        var msg = $elToAppendWL.data('error-msg');
        displayErrorMessage($elToAppendWL, msg);
      }
    });
  });

  $body.on('click', '.remove-product, .remove-prod-minicart', function (e) {
    e.preventDefault();
    e.stopPropagation();

    var $this = $(this);
    var isPwpProduct = $this.closest('.pwp-eligible');
    var productID = $this.data('pid');
    var url = $this.data('action');
    var uuid = $this.data('uuid');
    var updatedLineItems = $this.closest('.prdt-cart-details').attr('updatedLineItems');
    if (updatedLineItems) {
      var urlParams = {
        pid: productID,
        uuid: uuid,
        updatedLineItems: updatedLineItems
      };
    } else {
      var urlParams = {
        pid: productID,
        uuid: uuid
      };
    }
    url = cartHelper.appendToUrl(url, urlParams);
    var isMiniCart = $this.hasClass('remove-prod-minicart');
    var $currElement;
    if (isMiniCart) {
      $currElement = $('.uuid-' + uuid)
        .closest('.prdt-section')
        .prev('.prdt-section');
    }
    $('body > .modal-backdrop').remove();
    $('.cart-error').html('');

    if (isMiniCart) {
      $('.minicart .popover').spinner().start();
    } else {
      $.spinner().start();
    }
    $.ajax({
      url: url,
      type: 'get',
      dataType: 'json',
      success: function (data) {
        if (isMiniCart) {
          $body.trigger('adobeTagManager:quantityUpdateFromMiniCart', data);
        } else {
          $body.trigger('adobeTagManager:udpateCartQuantity', data);
        }

        if (data.basket.items.length === 0) {
          $('.pdt-cntr').empty().append(data.basket.emptyCartHtml);
          $('.shopping-bag-count').empty();
          $('.cart-summary-section').remove();
          $('.minicart-quantity').empty();
          $('.minicart-link').attr({
            'aria-label': data.basket.resources.minicartCountOfItems,
            title: data.basket.resources.minicartCountOfItems
          });
          $('.minicart .popover').empty();
          $('.minicart .popover').removeClass('show');
          $body.removeClass('modal-open');
          $('html').removeClass('veiled');
          $('.minicart-overlay').removeClass('background').removeAttr('style');

          if(window.innerWidth >= 1024) {
            window.onscroll = function() {};
          } else {
            $body.css({
              'overflow-y': 'auto'
            });
          }

          const $cartRecommendationUmal = $('.cart-recommendation-umal');

          if ($cartRecommendationUmal.length > 0 && $cartRecommendationUmal.html().trim().length === 0) {
            $body.trigger('cart:emptycart');
          }
          if ($cartRecommendationUmal.hasClass('d-none')) {
            $cartRecommendationUmal.removeClass('d-none');
            window.hbcSlider.cartReccommendationSliderInit();
          }
        } else {
          if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
            for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
              $('.uuid-' + data.toBeDeletedUUIDs[i]).remove();
            }
          }

          $('.uuid-' + uuid).remove();
          if (!data.basket.hasBonusProduct) {
            $('.bonus-product').remove();
          }
          $('.minicart .popover .minicart-item-count').empty().append(data.basket.items.length);
          $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
          cartHelper.updateCartTotals(data.basket);
          cartHelper.updateItemsHTML(data.basket);
          cartHelper.updateApproachingDiscounts(data.basket.approachingDiscounts);
          cartHelper.updateAppliedCouponCount(data.basket);
          cartHelper.updateTotalSavings(data.basket);
          cartHelper.updateDiscountsHtml(data.basket);
          $body.trigger('setShippingMethodSelection', data.basket);
          cartHelper.validateBasket(data.basket);

          const $cartRecommendationUmal = $('.cart-recommendation-umal');

          if (!$cartRecommendationUmal.hasClass('d-none')) {
            $cartRecommendationUmal.addClass('d-none');
          }
          $('.remove-prod-minicart').closest('.product-summary .prdt-section').first().find('.brand-name').focus();

          // we are reloading the page to initiate the payment js functions which are to be triggered on the page load
          if (data && data.deletedItem && data.deletedItem.productName && data.deletedItem.productName.toLowerCase().indexOf('saks +') !== -1) {
            window.location.reload();
          }
        }

        $body.trigger('cart:update');
        if (isMiniCart) {
          setMiniCartScrollHeight();
        }
        if (
          data.releatedBonusPLIs &&
          data.releatedBonusPLIs.length > 0 &&
          data.mainProdItem &&
          data.releatedBonusPLIs.indexOf(data.mainProdItem.productID) > -1
        ) {
          $('.cannot-shipping-message.bonus-soldout').addClass('d-none');
        }

        toggleKlarnaMessage(data.basket);
        handleMixedCartForApplePay(data);
        $.spinner().stop();
      },
      error: function (err) {
        if (err.responseJSON.redirectUrl) {
          window.location.href = err.responseJSON.redirectUrl;
        } else {
          cartHelper.createErrorNotification(err.responseJSON.errorMessage);
          $.spinner().stop();
        }
      }
    });
  });

  $('body').on('afterRemoveFromCart', function (e, data) {
    e.preventDefault();
    confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
  });

  $('.optional-promo').click(function (e) {
    e.preventDefault();
    $('.promo-code-form').toggle();
  });
  $body.on('click', '.cart-delete-confirmation-btn', function (e) {
    e.preventDefault();

    var $this = $(this);
    var productID = $this.data('pid');
    // eslint-disable-next-line no-unused-vars
    var url = $this.data('action');
    var uuid = $this.data('uuid');
    var urlParams = {
      pid: productID,
      uuid: uuid
    };

    url = cartHelper.appendToUrl(url, urlParams);

    $('body > .modal-backdrop').remove();

    var couponCode = $this.data('code');
    // eslint-disable-next-line no-redeclare
    var uuid = $this.data('uuid');
    var $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
    var $productToRemoveSpan = $('.coupon-to-remove');

    $deleteConfirmBtn.data('uuid', uuid);
    $deleteConfirmBtn.data('code', couponCode);
    $productToRemoveSpan.empty().append(couponCode);
  });

  $('body').on('showOptionalProduct', function (e, data) {
    getOptionalBonusModalHtmlElement();
    $.spinner().start();
    $.ajax({
      url: $(data).data('url'),
      method: 'GET',
      dataType: 'json',
      success: function (resp) {
        fillChoiceOfBonusModalElement(resp);
        $.spinner().stop();
      },
      error: function () {
        $.spinner().stop();
      }
    });
    return;
  });

  $body.on('click', '.bonus-product-button', function () {
    let $this = $(this);

    $this.addClass('launched-modal');
    if ($this.data('intermediate')) {
      $body.trigger('showOptionalProduct', this);
      return;
    }
    $.spinner().start();
    $.ajax({
      url: $(this).data('url'),
      method: 'GET',
      dataType: 'json',
      success: function (data) {
        window.base.methods.editBonusProducts(data);
        $.spinner().stop();
      },
      error: function () {
        $.spinner().stop();
      }
    });
  });

  $body.on('hidden.bs.modal', '#chooseBonusProductModal', function () {
    var $chooseBonusProductModal = $('#chooseBonusProductModal');

    $chooseBonusProductModal.remove();
    $chooseBonusProductModal.next().remove();
    if ($('#editProductModal').hasClass('show')) {
      $body.addClass('modal-open');
      if ($(window).width() > 1024) {
        $body.css('padding-right', '17px');
      }
    }

    var $cartPage = $('.cart-page');
    var $launchedModal = $('.launched-modal');

    if ($cartPage.length) {
      $launchedModal.find('.btn-outline-primary').trigger('focus');
      $launchedModal.removeClass('launched-modal');
    } else {
      $('.product-detail .add-to-cart').focus();
    }
  });

  $body.on('click', '.cart-page .item-attributes .edit .brand-name', function (e) {
    e.preventDefault();
    // handle events after ajax, since to avaoid multiple time registration of events
    var $parent = $(this).parent();
    var editProductUrl = $parent.data('href') ? $parent.data('href') : $parent.attr('href');
    getModalHtmlElement();
    fillModalElement(editProductUrl);
  });

  $body.on('click', '.cart-page .item-attributes .edit .line-item-name', function (e) {
    e.preventDefault();
    // handle events after ajax, since to avaoid multiple time registration of events
    var $parent = $(this).parent().parent();
    var editProductUrl = $parent.data('href') ? $parent.data('href') : $parent.attr('href');
    getModalHtmlElement();
    fillModalElement(editProductUrl);
  });

  $body.on(
    'click',
    '.cart-page .product-edit .item-edit, .cart-page .bundle-edit .edit, .cart-page .item-image .edit, .product-tile .item-edit',
    function (e) {
      e.preventDefault();
      // handle events after ajax, since to avaoid multiple time registration of events
      getModalHtmlElement();
      fillModalElement($(this).data('href'));
    }
  );

  // wishlist changes done to add to cart from wishlist
  if ($('.page').data('action') === 'Cart-Show') {
    $(document).on('change', 'select[class*="select-"]', function (e) {
      e.preventDefault();

      var $this = $(this);
      var $productContainer = $this.closest('.set-item');
      if (!$productContainer.length) {
        $productContainer = $this.closest('.card.product-info');
      }
      attributeSelect(e.currentTarget.value, $productContainer);
    });
    // Changes made to cart - add to cart
    $(document).on('click', 'button.add-to-cart, button.add-to-cart-global', function () {
      var addToCartUrl;
      var pid;
      var pidsObj;
      var setPids;

      var $this = $(this);
      var isReadyToOrder = $this.attr('data-readytoorder');
      if (isReadyToOrder !== 'true') {
        var $card = $this.closest('.card');

        $('.select-size-color').html($this.data('readytoordertext'));
        $card.find('.alert-msg').removeClass('d-none');
        $card.find('.selected-option').addClass('error');
        return;
      }

      var wlRemoveUrl = $this.data('wishlist-remove');

      $body.trigger('product:beforeAddToCart', this);

      if ($('.set-items').length && $this.hasClass('add-to-cart-global')) {
        setPids = [];

        $('.product-detail').each(function () {
          if (!$this.hasClass('product-set-detail')) {
            setPids.push({
              pid: $this.find('.product-id').text(),
              qty: $this.find('.quantity-select').val(),
              options: getOptions($this)
            });
          }
        });
        pidsObj = JSON.stringify(setPids);
      }
      pid = $this.closest('.product').data('pid');
      addToCartUrl = $this.closest('.wishlist-cart').data('addtobagurl');

      if (pid === undefined && $this.data('wishlist-addtobag') === true && $this.attr('data-addwl-pid') !== undefined) {
        pid = $this.attr('data-addwl-pid');
        addToCartUrl = $this.siblings('.add-to-cart-url').val();
      }

      var $productContainer = $this.closest('.product-detail');
      if (!$productContainer.length) {
        $productContainer = $this.closest('.quick-view-dialog').find('.product-detail');
      }

      var form = {
        pid: pid,
        pidsObj: pidsObj,
        childProducts: getChildProducts(),
        quantity: 1,
        loadBasketModel: true
      };

      if (!$('.bundle-item').length) {
        form.options = getOptions($productContainer);
      }

      $this.trigger('updateAddToCartFormData', form);
      if (addToCartUrl) {
        $.spinner().start();
        $.ajax({
          url: addToCartUrl,
          method: 'POST',
          data: form,
          success: function (data) {
            if (!data.error) {
              let productToRemove = $('#editProductModal').find('.product-detail').data('wlmaster-pid');
              if ($('#editProductModal').length > 0) {
                $('#editProductModal').modal('hide');
              }

              // return and trigger a message product addition exceeded the basket preference limit
              if (data.message && data.message === 'LIMIT_EXCEEDED') {
                $body.trigger('triggerBasketLimitMsgModal');
                $.spinner().stop();
              } else {
                if ($($this).hasClass('prod_wl_bttn')) {
                  // Commented bagAdd event as per ticket SFDEV-3970
                  // $body.trigger('adobeTagManager:addToBag', data);
                } else if ($($this).hasClass('wishlist-add-to-bag')) {
                  // changes made to remove the product from wishlist of the same is being added from WL - Start
                  $body.trigger('adobeTagManager:moveFavToBag', data);
                } else {
                  $body.trigger('adobeTagManager:addToBag', data);
                }
                window.base.showAddToCartConfirmation(data.addToCartConfirmationModal);
                $body.trigger('product:afterAddToCart', data);
                if(wlRemoveUrl){
                  $.ajax({
                    url: wlRemoveUrl,
                    type: 'get',
                    dataType: 'json',
                    success: function () {
                      // when remove the product in the wishlist, there is one more parent in the web than mobile app
                      if ($('body.mobile-app-CART_CHECKOUT_MIGRATION_VERSION_changes').length) {
                        $this.closest('div.product').remove();
                      } else {
                        $this.closest('div.product').parent().remove();
                      }
                      if (productToRemove != undefined) {
                        $('.wishlist-cart')
                          .find('div.product[data-pid=' + productToRemove + ']')
                          .parent()
                          .remove();
                      }
                      if ($('.wishlist-cart-slider').length > 0) {
                        removeWishlistSlider();
                        window.hbcSlider.hbcSliderInit('wishlist-cart-slider');
                      }
                    },
                    error: function () {
                        var $elToAppendWL = $('.wishlistItemCards');
                        var msg = $elToAppendWL.data('error-msg');
                        displayErrorMessage($elToAppendWL, msg);
                    }
                });
                }

                if (data && data.totals && data.totals.discountsHtml) {
                  $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                }
                cartHelper.updateItemsHTML(data);
                if (data && data.totals) {
                  cartHelper.updateCartTotals(data);
                  cartHelper.updateAppliedCouponCount(data);
                  cartHelper.updateTotalSavings(data);
                  toggleKlarnaMessage(data);
                }
                window.formFields.adjustForAutofill();
                floatinglabelWishlist();
                let $cartRecommendationUmal = $('.cart-recommendation-umal');
                if ($cartRecommendationUmal.length > 0 && $cartRecommendationUmal.html().trim().length === 0) {
                  $body.trigger('cart:emptycart');
                }
                if (data.items && data.items.length === 0 && $cartRecommendationUmal.hasClass('d-none')) {
                  $cartRecommendationUmal.removeClass('d-none');
                  window.hbcSlider.cartReccommendationSliderInit();
                } else if (data.items && data.items.length > 0 && !$cartRecommendationUmal.hasClass('d-none')) {
                  $cartRecommendationUmal.addClass('d-none');
                }


                if (!$('.wishlist-cart-slider').find('.product').length) {
                  $('.cart-wl-content').addClass('d-none');
                }
                window.hbcSlider.cartReccommendationSliderInit();
                $.spinner().stop();
              }
            }
            handleMixedCartForApplePay(data);
            $.spinner().stop();
          },
          error: function () {
            $.spinner().stop();
          }
        });
      }
    });
  }

  // Changes done for cart - add to wishlist - Start
  $body.on('click', '.cart-page .product-move .move', function (e) {
    e.preventDefault();
    var $this = $(this);
    var uuid = $this.data('uuid');
    var pid = $this.data('pid');
    var addToWLUrl = $this.attr('href');
    var actionurl = $this.data('action');
    var url = $('.prdt-cart-details').data('wltileurl');

    url = cartHelper.appendToUrl(url, { pid: pid });

    var urlParams = {
      pid: pid,
      uuid: uuid
    };

    actionurl = cartHelper.appendToUrl(actionurl, urlParams);

    $.spinner().start();

    $.ajax({
      url: addToWLUrl,
      type: 'post',
      dataType: 'json',
      data: {
          pid: pid
      },
      success: function (response) {
        $.ajax({
          url: url,
          success: function (response) {
            try {
              var productData = {};
              productData.brand = $(response).find('.tileproduct-brandname').data('brandname');
              productData.bopus_store_id = $(response).find('.tileproduct-storeid').data('bopus_store_id');
              productData.code = $(response).find('.tileproduct-atm-code').data('atm-code');
              productData.name = $(response).find('.tileproduct-name').data('product-name');
              productData.original_price = $(response).find('.tileproduct-orignalprice').data('orignalprice').toString();
              productData.price = $(response).find('.tileproduct-price').data('price').toString();
              productData.quantity = '1';
              productData.selected_sku = $(response).find('.tileproduct-detail').data('pid').toString();
              productData.ship_from_store_id = '';

              $body.trigger('adobeTagManager:cartMoveToFav', productData);
              removeWishlistSlider();
              $('.cart-wl-content').removeClass('d-none');
              var wishlistCart = $body.find('.wishlist-cart');
              let $tileWrapper = $('<div class="tile-wrapper tile-hide-swatches"></div>');
              let $tileSlider = wishlistCart.find('.wishlist-cart-slider');
              if (wishlistCart && $tileSlider.length === 0) {
                wishlistCart
                  .find('.row')
                  .html(
                    '<div class="wishlist-cart-heading slider-heading text-center"><a class="link-wishlist" href="' +
                      wishlistCart.data('wishlisturl') +
                      '">' +
                      '<span class="svg-24-avenue-wishlist-heart-dims svg-24-avenue-wishlist-heart heart-icon"></span> ' +
                      wishlistCart.data('wishlistheader') +
                      '</a></div><div class="col-12 hbc-slider wishlist-cart-slider"></div>'
                  );
                $tileWrapper.append(response);
                wishlistCart.find('.wishlist-cart-slider').html($tileWrapper);
              } else {
                let $firstEl;
                $tileWrapper.append(response);

                if ($tileSlider.find('.product').length) {
                  $firstEl = $tileSlider.find('.product').eq(0).parent();
                  $firstEl.before($tileWrapper);
                } else if ($tileSlider.find('.veiwall-wrapper').length){
                  $firstEl = $tileSlider.find('.veiwall-wrapper').eq(0);
                  $firstEl.before($tileWrapper);
                } else {
                  $tileSlider.append($tileWrapper);
                }
              }
              // eslint-disable-next-line newline-per-chained-call
              $tileSlider.find('.product').eq(0).find('.wishlistTile').removeClass('select-wishlist').addClass('deselect-wishlist js-cart-wishlist-tile');
              // eslint-disable-next-line newline-per-chained-call
              window.hbcSlider.hbcSliderInit('wishlist-cart-slider');
              window.hbcSlider.cartReccommendationSliderInit();
            } catch (e) {}
            $.ajax({
              url: actionurl,
              type: 'get',
              dataType: 'json',
              success: function (data) {
                let $cartRecommendationUmal = $('.cart-recommendation-umal');

                if (data.basket.items.length === 0) {
                  $('.cart')
                    .empty()
                    .append(
                      '<div class="row"> ' + '<div class="col-12 text-center"> ' + '<h1>' + data.basket.resources.emptyCartMsg + '</h1> ' + '</div> ' + '</div>'
                    );
                  $('.pdt-cntr').empty().append(data.basket.emptyCartHtml);
                  $('.shopping-bag-count').empty();
                  $('.number-of-items').empty().append(data.basket.resources.numberOfItems);
                  $('.cart-summary-section').remove();
                  $('.minicart-quantity').empty();
                  $('.minicart-link').attr({
                    'aria-label': data.basket.resources.minicartCountOfItems,
                    title: data.basket.resources.minicartCountOfItems
                  });
                  $('.minicart .popover').empty();
                  $('.minicart .popover').removeClass('show');
                  $body.removeClass('modal-open');
                  $('html').removeClass('veiled');
                  if ($cartRecommendationUmal.length > 0 && $cartRecommendationUmal.html().trim().length === 0) {
                    $body.trigger('cart:emptycart');
                  }
                  if ($cartRecommendationUmal.hasClass('d-none')) {
                    $cartRecommendationUmal.removeClass('d-none');
                    window.hbcSlider.cartReccommendationSliderInit();
                  }
                } else {
                  if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                    for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                      $('.uuid-' + data.toBeDeletedUUIDs[i]).remove();
                    }
                  }
                  $('.uuid-' + uuid).remove();
                  if (!data.basket.hasBonusProduct) {
                    $('.bonus-product').remove();
                  }
                  $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                  cartHelper.updateCartTotals(data.basket);
                  cartHelper.updateApproachingDiscounts(data.basket.approachingDiscounts);
                  cartHelper.updateAppliedCouponCount(data.basket);
                  cartHelper.updateTotalSavings(data.basket);
                  $body.trigger('setShippingMethodSelection', data.basket);
                  cartHelper.validateBasket(data.basket);
                  if (!$cartRecommendationUmal.hasClass('d-none')) {
                    $cartRecommendationUmal.addClass('d-none');
                  }
                }
                toggleKlarnaMessage(data.basket);
                $body.trigger('cart:update');
                cartHelper.updateItemsHTML(data.basket);
                window.formFields.adjustForAutofill();
                floatinglabelWishlist();
                handleMixedCartForApplePay(data);
                $.spinner().stop();
              },
              error: function (err) {
                if (err.responseJSON.redirectUrl) {
                  window.location.href = err.responseJSON.redirectUrl;
                } else {
                  cartHelper.createErrorNotification(err.responseJSON.errorMessage);
                  $.spinner().stop();
                }
              }
            });
          }
        });
      }
    });
  });
  // Changes done for cart - add to wishlist - End

  $body.on('shown.bs.modal', '#editProductModal', function () {
    const $editProductModal = $('#editProductModal');
    $editProductModal.siblings().attr('aria-hidden', 'true');
    $editProductModal.find('.close').focus();
  });

  $('body').on('cart:emptycart', () => {
    var $cartRecommendationUmal = $('.cart-recommendation-umal')
    if ($cartRecommendationUmal.length > 0 && $cartRecommendationUmal.data('url')) {
      var url = $cartRecommendationUmal.data('url');
      $.ajax({
        url: url,
        method: 'GET',
        success: data => {
          $cartRecommendationUmal.append(data);
        }
      });
    }
  });

  $body.on('click', '.promo-click', function (e) {
    e.stopImmediatePropagation();

    var $this = $(this);

    $this.toggleClass('open-state');
    $this.closest('.form-group').find('.promo-detail-section').toggleClass('expand');
  });

  $body.on('hidden.bs.modal', '#editProductModal', function () {
    $('#editProductModal').siblings().attr('aria-hidden', 'false');
  });

  $body.on('keydown', '#editProductModal', function (e) {
    var focusParams = {
      event: e,
      containerSelector: '#editProductModal',
      firstElementSelector: '.close',
      lastElementSelector: '.update-cart-product-global',
      nextToLastElementSelector: '.modal-footer .quantity-select'
    };
    focusHelper.setTabNextFocus(focusParams);
  });

  // trigger event waitListAddStart on Saks plus landing page
  $('.js-wait-list-form')
    .find('input.js-waitlist-email')
    .on('focus', function () {
      $body.trigger('adobe:waitListStart');
    });

  $('.js-wait-list-form')
    .find('input.js-waitlist-email')
    .on('blur', function () {
      $('.js-wait-list-form').find('input.js-waitlist-email').off('focus');
    });

  $('body').on('product:updateAvailability', function (e, response) {
    // bundle individual products
    $('.product-availability', response.$productContainer)
      .data('ready-to-order', response.product.readyToOrder)
      .data('available', response.product.available)
      .find('.availability-msg')
      .html(response.message);
    $('div.error-msg').empty();
    if (!response.product.available && !response.product.waitlistable) {
      if (!response.product.availability.isInPurchaselimit) {
        createErrorNotificationQuickView(response.product.availability.isInPurchaselimitMessage);
      } else if (response.product.availability.messages && response.product.availability.messages.length > 0) {
        createErrorNotificationQuickView(response.product.availability.messages[0]);
      }
    }

    var dialog = $(response.$productContainer).closest('.quick-view-dialog');

    if ($('.product-availability', dialog).length) {
      // bundle all products
      var allAvailable = $('.product-availability', dialog)
        .toArray()
        .every(function (item) {
          return $(item).data('available');
        });

      var allReady = $('.product-availability', dialog)
        .toArray()
        .every(function (item) {
          return $(item).data('ready-to-order');
        });

      $('.global-availability', dialog).data('ready-to-order', allReady).data('available', allAvailable);

      $('.global-availability .availability-msg', dialog)
        .empty()
        .html(allReady ? response.message : response.resources.info_selectforstock);
    } else {
      // single product
      $('.global-availability', dialog)
        .data('ready-to-order', response.product.readyToOrder)
        .data('available', response.product.available)
        .find('.availability-msg')
        .html(response.message);
    }
  });

  $('body').on('product:afterAttributeSelect', function (e, response) {
    var $modal = $('.modal.show');

    if ($modal.find('.product-quickview .bundle-items').length) {
      var $container = $modal.find(response.container);
      $container.data('pid', response.data.product.id);
      $container.find('.product-id').text(response.data.product.id);
      $container.find('.bf-product-id').text(response.data.product.id);
    } else {
      $modal.find('.product-quickview').data('pid', response.data.product.id);
      $modal.find('.bf-product-id').text(response.data.product.id);
    }
    if (window.TurnToCmd) {
      TurnToCmd('set', {
        //eslint-disable-line
        sku: response.data.product.id //eslint-disable-line
      }); //eslint-disable-line
    }
  });

  $body.on('change', '.quantity-select', function () {
    let selectedQuantity = $(this).val();
    $('.modal.show .update-cart-url').data('selected-quantity', selectedQuantity);
  });

  $body.on('change', '.options-select', function () {
    let selectedOptionValueId = $(this).children('option:selected').data('value-id');
    $('.modal.show .update-cart-url').data('selected-option', selectedOptionValueId);
  });

  $body.on('change', '.quantity-form select', function () {
    $('.qty-error-message').empty();
    let $this = $(this);
    let quantity = $this.val();
    if (!quantity && $this.parent().find('.quantity-val')) {
      quantity = $this.parent().find('.quantity-val').attr('max');
    }
    let productID = $this.data('pid');
    let url = $this.data('action');
    let uuid = $this.data('uuid');
    let shiptoSeleted = $('input[name="shipto_' + uuid + '"]').val();
    let urlParams = {
      pid: productID,
      quantity: quantity,
      uuid: uuid,
      shipto: shiptoSeleted && shiptoSeleted.length > 0 ? shiptoSeleted : null
    };
    let productCode = $this.data('atm-code');
    var isFromMiniCart = $this.closest('.mini-cart-container').length > 0;
    var currElementPos = 0;
    if (isFromMiniCart) {
      currElementPos = $this.closest('.product-summary').scrollTop();
    }
    url = cartHelper.appendToUrl(url, urlParams);

    $this.parents('.card').spinner().start();
    $('.error-messaging').addClass('giftcard-cart-page-error');

    $.ajax({
      url: url,
      type: 'get',
      context: this,
      dataType: 'json',
      success: function (data) {
        if (data && data.giftCardError) {
          updateGiftCardError(data);
        }
        if (data.error && !(data.canBeUpdated)) {
          handleUpdateQuantityErrors(data, $this, uuid, productID, productCode);
          return;
        }
        $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
        $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
        var availableATS = parseInt((data.availableATS || quantity), 10);
        if (quantity > availableATS) {
          setTimeout(function(){
        cartHelper.updateItemsHTML(data);
          }, 3000);
        }
        else{
          cartHelper.updateItemsHTML(data);
        }
        updateMiniCartItemsHTML(data);
        cartHelper.updateCartTotals(data);
        cartHelper.updateApproachingDiscounts(data.approachingDiscounts);
        updateAvailability(data, uuid);
        cartHelper.validateBasket(data);
        cartHelper.updateDiscountsHtml(data);
        cartHelper.updateAppliedCouponCount(data);
        cartHelper.updateTotalSavings(data);

        if (isFromMiniCart) {
          $body.trigger('adobeTagManager:quantityUpdateFromMiniCart', data);
          setMiniCartScrollHeight();
          $('.minicart .product-summary').each(function () {
            $(this).scrollTop(currElementPos);
          });
        } else {
          $body.trigger('adobeTagManager:udpateCartQuantity', data);
        }
        $this.data('pre-select-qty', quantity);

        $body.trigger('cart:update');
        if (data.error) {
          handleUpdateQuantityErrors(data, $this, uuid, productID, productCode);
          return;
        }
        $.spinner().stop();
      },
      error: function (err) {
        if (err.responseJSON) {
          handleUpdateQuantityErrors(err.responseJSON, $this, uuid, productID, productCode);
        }
        $.spinner().stop();
      }
    });
  });

  $('.shippingMethods').change(function () {
    let $this = $(this)
    let url = $this.attr('data-actionUrl');
    let urlParams = {
      methodID: $this.find(':selected').attr('data-shipping-id')
    };
    // url = cartHelper.appendToUrl(url, urlParams);

    $('.totals').spinner().start();
    $.ajax({
      url: url,
      type: 'post',
      dataType: 'json',
      data: urlParams,
      success: function (data) {
        if (data.error) {
          window.location.href = data.redirectUrl;
        } else {
          $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
          cartHelper.updateCartTotals(data);
          cartHelper.updateApproachingDiscounts(data.approachingDiscounts);
          cartHelper.updateAppliedCouponCount(data);
          cartHelper.updateTotalSavings(data);
          cartHelper.validateBasket(data);
        }
        $.spinner().stop();
      },
      error: function (err) {
        if (err.redirectUrl) {
          window.location.href = err.redirectUrl;
        } else {
          cartHelper.createErrorNotification(err.responseJSON.errorMessage);
          $.spinner().stop();
        }
      }
    });
  });
  $body.on('click', '.add-sec-associate', function (e) {
    let $this = $(this);
    $('.js-comx-container-2').toggleClass('d-none');
    $this.toggleClass('expanded');
    let $fields = $('.js-comx-container-2').find('select,input');
    if ($this.hasClass('expanded')) {
      //$fields.prop('required', true);
    } else {
      //$fields.prop('required', false);
      $fields.removeClass('is-invalid').next('span').remove();
      $fields.val('');
      $('#comxAssociate2').closest('.form-group').find('.invalid-feedback').empty();
      $('#comxAssociate2').closest('.form-group').find('label').removeClass('is-invalid').removeClass('input-focus');
    }
  });
  $body.on('click', '.add-comx-note', function (e) {
    let $this = $(this);
    $('.js-comx-add-note').toggleClass('d-none');
    $this.toggleClass('expanded');
    $this.hasClass('expanded') ? '' : $('.js-comx-add-note').find('textarea').val('').next('label').removeClass('input-focus');
  });
  $body.on('keyup', '#comxAssociate2', function (event) {
    let $this = $(this);
    if ($this.val().length > 0) {
      $this.prop('required', true);
    } else {
      let $comxAssociate = $('#comxAssociate2');
      let $formGroup = $comxAssociate.closest('.form-group');

      $comxAssociate.prop('required', false);
      $comxAssociate.removeClass('is-invalid');
      $formGroup.find('label').removeClass('is-invalid');
      $comxAssociate.next('span').remove();
      $formGroup.find('.invalid-feedback').empty();
    }
  });

  $body.on('change', '#comxStore2', function (e) {
    let $comxAssociate = $('#comxAssociate2');
    let $formGroup = $comxAssociate.closest('.form-group');

    if ($(this).val() != '') {
      $comxAssociate.prop('required', true);
    } else {
      $comxAssociate.prop('required', false);
      $comxAssociate.removeClass('is-invalid');
      $formGroup.find('label').removeClass('input-focus is-invalid');
      $comxAssociate.next('span.invalid').remove();
      $formGroup.find('.invalid-feedback').empty();
    }
  });

  $body.on('click', '.productDetailModal .back-icon, .productDetailModal .close', function () {
    $('.productDetailModal').removeClass('show').attr('aria-modal style');
  });

  $body.on('click', '.update-cart-product-global', function (e) {
    e.preventDefault();

    let $this = $(this);

    if (!$this.data('readytoorder')) {
      $('.select-size-color').html($this.data('readytoordertext'));
      return;
    }

    let $updateCartUrl = $this.closest('.cart-and-ipay').find('.update-cart-url');
    let updateProductUrl = $updateCartUrl.val();
    let selectedQuantity = $updateCartUrl.data('selected-quantity');
    let selectedOptionValueId = $updateCartUrl.data('selected-option');
    let uuid = $updateCartUrl.data('uuid');
    let selectedShipto = $('input[name="shipto_' + uuid + '"]:checked').length > 0 ? $('input[name="shipto_' + uuid + '"]:checked').val() : null;
    let productID = window.base.getPidValue($this);
    let form = {
      uuid: uuid,
      pid: productID,
      quantity: selectedQuantity,
      selectedOptionValueId: selectedOptionValueId,
      selectedShipto: selectedShipto
    };

    $this.parents('.card').spinner().start();
    if (updateProductUrl) {
      $.ajax({
        url: updateProductUrl,
        type: 'post',
        context: this,
        data: form,
        dataType: 'json',
        success: function (data) {
          $('#editProductModal').modal('hide');
          if (data && data.giftCardError) {
            $('.error-messaging').addClass('giftcard-cart-page-error');
            updateGiftCardError(data);
          }
          $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
          cartHelper.updateCartTotals(data.cartModel);
          cartHelper.updateApproachingDiscounts(data.cartModel.approachingDiscounts);
          updateAvailability(data.cartModel, uuid);
          updateProductDetails(data, uuid);
          cartHelper.updateAppliedCouponCount(data.cartModel);
          cartHelper.updateTotalSavings(data.cartModel);
          if (data.uuidToBeDeleted) {
            $('.uuid-' + data.uuidToBeDeleted).remove();
          }
          cartHelper.validateBasket(data.cartModel);
          toggleKlarnaMessage(data.cartModel);
          $body.trigger('cart:update');

          $.spinner().stop();
        },
        error: function (err) {
          if (err.responseJSON.redirectUrl) {
            window.location.href = err.responseJSON.redirectUrl;
          } else {
            createErrorNotificationQuickView(err.responseJSON.errorMessage);
            $.spinner().stop();
          }
        }
      });
    }
  });

  $('#masterpass-payment').on('click', function(){
    $body.trigger('adobeTagManager:exitToPaymentService', 'masterpass');
  });


  /**
   * Initialize checkout
   *
   */
  function initCheckout() {
    $body.on('click', '.cart-summary-section .checkout-btn', function (e) {
      e.preventDefault();
      if (this.getAttribute('href').charAt(0) !== '#') {
        login.openLoginPopup($(this).attr('href'));
      }
    });
  }

  /**
   * initializing calculate tax
   *
   */
  function initCalculateTax() {
    $body.on('submit', '.cart-tax-calculation-form', function (e) {
      let $this = $(this);
      e.preventDefault();
      $body.spinner().start();
      $.ajax({
        url: $this.attr('action'),
        type: 'post',
        data: $this.serialize(),
        dataType: 'json',
        success: function (data) {
          $body.spinner().stop();
          $('.cart-summary-details .invalid-feedback-error').empty();
          if (!data.error) {
            cartHelper.updateCartTotals(data.data);
            $('.shipping-postal-code').text(data.postalCode);
            $('.postal-container').toggleClass('active');
            $('.tax_calculate').parents('.form-group').find('label, input').removeClass('is-invalid');
          } else {
            $('.cart-summary-details .invalid-feedback-error').html(data.error);
            $('.tax_calculate').parents('.form-group').find('label, input').addClass('is-invalid');
          }
        },
        error: function () {
          $body.spinner().stop();
        }
      });
    });

    $body.on('click', '.cart-summary-details .shipping-postal-code', function (e) {
      e.preventDefault();
      $('.postal-container').toggleClass('active');
    });

    $body.on('click', '.cart-summary-details .tax_cancel', function (e) {
      e.preventDefault();
      $('.postal-container').removeClass('active');
    });
  }

  function openCSCMOdel() {
    $body.on('click', '.js-open-csc-price-model', function (e) {
      let $this = $(this);
      let $editCSCPrice = $('#editCSCPrice');
      e.preventDefault();
      $body.spinner().start();
      let itemPrice = $this.closest('.cart-pdt-details').find('.formatted_price').last().text();
      let itemUUID = $this.data('pliuuid');
      $editCSCPrice.find('.csc-current-price').val(itemPrice);
      $editCSCPrice.find('#newPrice').val('');
      $('.price-override-reason-options').val('');
      $editCSCPrice.find('.csc-pli-uuid').val(itemUUID);
      $editCSCPrice.find('.csr_error_message').removeClass('alert alert-danger alert-dismissible').empty();
      $editCSCPrice.modal('show');
      $body.spinner().stop();
    });
  }

  $(document).on('keydown', '#newPrice', function (e) {
    if (e.ctrlKey === true && (e.which === 118 || e.which === 86)) {
      e.preventDefault();
    }

    let keyVal = e.which ? e.which : e.keyCode;
    if (keyVal === 46 || keyVal === 190 || keyVal === 8) {
      return true;
    } else if (!(keyVal >= 48 && keyVal <= 57)) {
      return false;
    }
  });

  $(document).on('cart:removeitem', function (e, data) {
    let itemContainer = $(e.target).closest('.uuid-' + data.uuid);
    if (itemContainer) {
      let removeButton = itemContainer.find('.remove-product, .remove-prod-minicart');
      if (removeButton.length === 0) {
        let removeButtonElement =
          "<button type='button' class='d-none remove-product' " +
          "data-target='.cart.cart-page #removeProductModal' data-pid='${data.pid}' " +
          "data-action=${data.url} data-uuid='${data.uuid}'>" +
          "<span aria-hidden='true' class='svg-image svg-36-avenue-Up_Copy svg-36-avenue-Up_Copy-dims'></span>" +
          '</button>';
        itemContainer.append(removeButtonElement);
        removeButton = itemContainer.find('.remove-product');
      }
      $(removeButton[0]).trigger('click');
    }
  });

  function submitCSCPrice() {
    $body.on('submit', '.price-override-form', function (e) {
      e.preventDefault();
      let $form = $(this);
      let pliuuid = $('#editCSCPrice .csc-pli-uuid').val();
      var $csrErrorMsg = $('.csr_error_message');
      $csrErrorMsg .empty();
      $csrErrorMsg .removeClass('alert alert-danger alert-dismissible');
      $body.spinner().start();
      $.ajax({
        url: $form.attr('action'),
        type: 'post',
        data: $form.closest('form').serialize(),
        dataType: 'json',
        success: function (data) {
          $body.spinner().stop();
          $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
          cartHelper.updateItemsHTML(data);
          updateMiniCartItemsHTML(data);
          cartHelper.updateCartTotals(data);
          cartHelper.updateApproachingDiscounts(data.approachingDiscounts);
          updateAvailability(data, pliuuid);
          cartHelper.validateBasket(data);
          cartHelper.updateDiscountsHtml(data);
          cartHelper.updateAppliedCouponCount(data);
          cartHelper.updateTotalSavings(data);
          $body.trigger('cart:update');
          if (!data.CSRSuccess && data.CSRerrorMessage) {
            $csrErrorMsg.empty().text(data.CSRerrorMessage);
            $csrErrorMsg.addClass('alert alert-danger alert-dismissible');
            var errorInfo = atmHelper.buildErrorInfo([], 'cart error', data.errorCSRerrorMessageMessage, 'server-side');
            $body.trigger('adobeTagManager:trackError',  errorInfo);
          } else {
            $('#editCSCPrice').modal('hide');
          }
          $body.spinner().stop();
        },
        error: function () {
          $body.spinner().stop();
        }
      });
    });
  }

  /**
   * Init on click of alternate image
   */
  function initAlternateImage() {
    $body.on('click', '.product-quickview .slick-next, .product-quickview .slick-prev', function () {
      $body.trigger('adobeTagManager:quickViewAltImageView');
    });
  }

  /**
   * Init on click of alternate image
   */
  function initAlternateBubble() {
    $body.on('click', '.product-quickview .slick-dots button', function () {
      $body.trigger('adobeTagManager:quickViewAltImageView');
    });
  }

  /**
   * Init Adobe Analytices paymentServiceExit event
   */
  function initPaymentServiceExit() {
    /*
     *   paypal cart button click event handled in LINK_PayPal/cartridges/int_paypal_sfra/cartridge/static/default/js/paypalUtils.js
     *   paypal billing button click event handled in LINK_PayPal/cartridges/int_paypal_sfra/cartridge/static/default/js/paypalMFRA.js
     */
    $('.pass-cart-buttons-container')
      .find('img#masterpass-payment')
      .on('click', function () {
        $body.trigger('adobeTagManager:exitToPaymentService', 'masterpass');
      });
  }

  /**
   * Trigger basket limit messaging modal
   */
  function triggerBasketMimitModal() {
    let $cartPageContent = $('.cart-page-content');
    let $welcomeAccountHeader = $('.welcome-account-header');
    if (
      ($cartPageContent.length > 0 &&
        $cartPageContent.attr('data-basketlimit-reached') != undefined &&
        $cartPageContent.attr('data-basketlimit-reached') === 'true') ||
      ($welcomeAccountHeader.length > 0 &&
        $welcomeAccountHeader.attr('data-basketlimit-reached') != undefined &&
        $welcomeAccountHeader.attr('data-basketlimit-reached') === 'true')
    ) {
      $body.trigger('triggerBasketLimitMsgModal');
    }
  }

  function checkAvailabilityOnHover() {
    $body.on('mouseenter', '.product-tile', function (e) {
      // check avaiability of the product upon hover and update the button text and treatment on tile
      // Ajax call is necessary to determine the availability without compromising tile cache
      let $this = $(this);
      let performedCheck = $this.closest('.product-tile').hasClass('updated-availability');
      if (!performedCheck) {
        $this.closest('.product-tile').addClass('updated-availability');
        var url = $this.closest('.product-tile').data('availabilityurl');
        var $productContainer = $this.closest('.product-tile');
        var atcButton = $this.closest('.product-tile').find('.ajax-update-atc-button');
        var pid = $this.closest('.product').data('pid');
        if (!url) {
          return;
        }
        // removing hover due to sfsx-2039
        $.ajax({
          url: url,
          method: 'GET',
          success: function (data) {
            atcButton.attr('disabled', !data.product.available);
            if (!data.product.available) {
              atcButton.addClass('soldout');
              atcButton.text(data.resources.soldout);
            } else if (data.product.preOrder && data.product.preOrder.applicable && data.product.preOrder.applicable === true) {
              atcButton.removeClass('soldout');
              atcButton.text(data.product.preOrder.preorderButtonName);
            } else {
              atcButton.removeClass('soldout');
              atcButton.text(data.resources.addtobag);
            }
          },
          error: function () {}
        });
      }
    });
  }

  function loadCartWishlistAsync() {
    if ($('.page').data('action') === 'Cart-Show') {
      let url = $('.cart-page-content').data('cart-wishlist');
      if (url) {
        $.ajax({
          url: url,
          success: function (response) {
            $body.find('.cart-wl-content').html(response);
            window.hbcSlider.hbcSliderInit('wishlist-cart-slider');
            window.hbcSlider.cartReccommendationSliderInit();

            var $elCartWishlist = $('.wishlist-cart');

            if ($elCartWishlist.length > 0) {
              let $wishListTile = $elCartWishlist.find('.wishlistTile');

              $elCartWishlist.find('.product-tile').find('.image-container').addClass('wishlist_checked');
              $wishListTile.find('span').addClass('svg-svg-96-heart-gray svg-svg-96-heart-gray-dims');
              $wishListTile.removeClass('select-wishlist').addClass('deselect-wishlist js-cart-wishlist-tile');
              $elCartWishlist.find('button.prdt_tile_btn').addClass('wishlist-tile-atc');
              $elCartWishlist.find('button.prdt_tile_btn:not(.add-to-cart)').addClass('item-edit');
            }
          }
        });
      }
    }
  }

  function trackSoldOutProducts() {
    var errorMessage;
    var errorProducts = [];
    var $productInfo = $('.product-info');

    $productInfo.each(function() {
        var $this = $(this);
        var shippingError = $this.find('.cannot-ship-error');
        if (shippingError.length && $this.data('atm-selectedsku')) {
            errorProducts.push({
              code: $this.data('atm-code'),
              selected_sku: $this.data('atm-selectedsku')
            });
            errorMessage = shippingError.text();
        }
    });

    //Wait until atm lib is ready receive events
    setTimeout(function() {
      if (errorMessage) {
        var errorInfo = atmHelper.buildErrorInfo(errorProducts, 'cart error', errorMessage, 'server-side');
        $body.trigger('adobeTagManager:trackError',  errorInfo);
      }
    }, 10000)
  }

  window.hbcTooltip.tooltipInit();
  initSignIn();
  initCheckout();
  initCalculateTax();
  initValidateOptionalBonusSelect();
  initOptionalBonusSelect();
  initChooseBonusProductDetails();
  openCSCMOdel();
  submitCSCPrice();
  initAlternateImage();
  initAlternateBubble();
  initPaymentServiceExit();
  triggerBasketMimitModal();
  checkAvailabilityOnHover();
  loadCartWishlistAsync();
  pwpAddToCart();
  pwpSelectOffer();
  applePayCartButton.applePayButtonInit();
  trackSoldOutProducts();
  updateAddToCart();
  updateGiftCardError();
};
